import React from "react";
import { useMediaQuery } from "react-responsive";

import { Link } from "react-scroll";
import { NavLink } from 'react-router-dom';
import logoShort from "../../assets/images/logo_MJ_short.png"
import logoMenuBurger from "../../assets/images/logo_burger_menu_color.png"
import logoContact from "../../assets/images/logo_contact_color.png"

import HomeIcon from "@material-ui/icons/Home";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import WorkIcon from "@material-ui/icons/Work";
import SchoolIcon from "@material-ui/icons/School";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

import './header.scss';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const Header = ({ handleClickOpenNav, handleClickLogo, handleClickNav }) => {

  return (
    <div>
      <Mobile>
        <header className="header" >
          <NavLink
            className="headerLogoContent"
            to="/contact"
            onClick={handleClickLogo}
          >
            <img className="logoMenuBurger" src={logoContact} alt="logo du site" />
          </NavLink>
          <Link
            to="home"
            smooth={true}
            offset={0}
            duration={1000}
            onClick={handleClickLogo}
          >
            <img className="logoShort" src={logoShort} alt="logo du site" />
          </Link>
          <img className="logoMenuBurger" src={logoMenuBurger} alt="logo du site" onClick={handleClickOpenNav} />
        </header>
      </Mobile>
      <Tablet>
        <header className="header_tablet" >
          <Link
            className="headerButtonNav_tablet"
            onClick={handleClickNav}
            to="skill"
            smooth={true}
            offset={0}
            duration={700}
          >
            Compétences
            <CheckBoxIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
          </Link>
          <Link
            className="headerButtonNav_tablet"
            onClick={handleClickNav}
            to="xp"
            smooth={true}
            offset={0}
            duration={700}
          >
            Expériences
            <WorkIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
          </Link>
          <div className="buttonsHomeContact_tablet">
            <Link
              to="home"
              smooth={true}
              offset={0}
              duration={1000}
              onClick={handleClickLogo}
            >
              <img className="logoShort_tablet" src={logoShort} alt="logo du site" />
            </Link>
            <NavLink
              className="headerLogoContent_tablet"
              to="/contact"
              onClick={handleClickNav}
            >
              <img className="logoMenuBurger_tablet" src={logoContact} alt="logo du site" />
            </NavLink>
          </div>
          <Link
            className="headerButtonNav_tablet"
            onClick={handleClickNav}
            to="formation"
            smooth={true}
            offset={0}
            duration={700}
          >Formations
          <SchoolIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
          </Link>
          <Link
            className="headerButtonNav_tablet"
            onClick={handleClickNav}
            to="description"
            smooth={true}
            offset={0}
            duration={700}
          >Qui suis-je ?
          <PermIdentityIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
          </Link>
        </header>
      </Tablet>
      <Desktop>
        <header className="header_desktop" >
          <Link
            className="headerButtonNav_desktop"
            onClick={handleClickNav}
            to="skill"
            smooth={true}
            offset={0}
            duration={700}
          >
            Compétences
            <CheckBoxIcon style={{ fontSize: 40 }} className="icon_header_desktop" />
          </Link>
          <Link
            className="headerButtonNav_desktop"
            onClick={handleClickNav}
            to="xp"
            smooth={true}
            offset={0}
            duration={700}
          >
            Expériences
            <WorkIcon style={{ fontSize: 40 }} className="icon_header_desktop" />
          </Link>
          <div className="buttonsHomeContact_desktop">
            <Link
              to="home"
              smooth={true}
              offset={0}
              duration={1000}
              onClick={handleClickLogo}
            >
              <img className="logoShort_desktop" src={logoShort} alt="logo du site" />
            </Link>
            <NavLink
              className="headerLogoContent_desktop"
              to="/contact"
              onClick={handleClickNav}
            >
              <img className="logoMenuBurger_desktop" src={logoContact} alt="logo du site" />
            </NavLink>
          </div>
          <Link
            className="headerButtonNav_desktop"
            onClick={handleClickNav}
            to="formation"
            smooth={true}
            offset={0}
            duration={700}
          >Formations
          <SchoolIcon style={{ fontSize: 40 }} className="icon_header_desktop" />
          </Link>
          <Link
            className="headerButtonNav_desktop"
            onClick={handleClickNav}
            to="description"
            smooth={true}
            offset={0}
            duration={700}
          >Qui suis-je ?
          <PermIdentityIcon style={{ fontSize: 40 }} className="icon_header_desktop" />
          </Link>
        </header>
      </Desktop>
    </div>
  );
};

export default Header;