import React from "react";

import './footer.scss';


const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="cardsPageEnd">
        <div className="cardsContainEnd">
          <p>Réalisé par MANDIN Jérémie - 2021</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;