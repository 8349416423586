import React from "react";

import { Link } from "react-scroll";

import HomeIcon from "@material-ui/icons/Home";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import WorkIcon from "@material-ui/icons/Work";
import SchoolIcon from "@material-ui/icons/School";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

import './navhome.scss';


const NavHome = ({ handleClickNav }) => {

  return (
    <div>
      <div className="dropdownContent">
        <Link
          className="headerDropdownButtonItem"
          onClick={handleClickNav}
          to="skill"
          smooth={true}
          offset={0}
          duration={700}
        >
          Compétences
          <CheckBoxIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
        </Link>
        <Link
          className="headerDropdownButtonItem"
          onClick={handleClickNav}
          to="xp"
          smooth={true}
          offset={0}
          duration={700}
        >
          Expériences
          <WorkIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
        </Link>
        <Link
          className="headerDropdownButtonItem"
          onClick={handleClickNav}
          to="formation"
          smooth={true}
          offset={0}
          duration={700}
        >Formations
        <SchoolIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
        </Link>
        <Link
          className="headerDropdownButtonItem"
          onClick={handleClickNav}
          to="description"
          smooth={true}
          offset={0}
          duration={700}
        >Qui suis-je ?
        <PermIdentityIcon style={{ fontSize: 30 }} className="icon_header_tablet" />
        </Link>
      </div>
    </div>
  );
};

export default NavHome;

