import React from 'react';

import { Switch, Route } from 'react-router-dom';
import Contact from '../../components/Contact';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import HeaderContact from '../../components/HeaderContact';
import Page404 from '../../components/Page404';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Home from '../home';


const Pages = ({
  clickOpenNav,
  setClickOpenNav,
  handleClickNav,
  handleClickOpenNav,
  handleClickLogo,
}) => {

  return (
    <div>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Header
            handleClickOpenNav={handleClickOpenNav}
            handleClickLogo={handleClickLogo}
            handleClickNav={handleClickNav}
          />
          <Home
            clickOpenNav={clickOpenNav}
            setClickOpenNav={setClickOpenNav}
            handleClickNav={handleClickNav}
            handleClickOpenNav={handleClickOpenNav}
            handleClickLogo={handleClickLogo}
          />
        </Route>
        <Route exact path="/contact">
          <HeaderContact
            handleClickOpenNav={handleClickOpenNav}
            handleClickLogo={handleClickLogo}
            handleClickNav={handleClickNav}
          />
          <Contact
            clickOpenNav={clickOpenNav}
            setClickOpenNav={setClickOpenNav}
            handleClickNav={handleClickNav}
            handleClickOpenNav={handleClickOpenNav}
            handleClickLogo={handleClickLogo} />
        </Route>
        <Route exact path="*">
          <HeaderContact
            handleClickOpenNav={handleClickOpenNav}
            handleClickLogo={handleClickLogo}
          />
          <Page404 />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export default Pages;

