import React from "react";
import { useMediaQuery } from "react-responsive";


import "./skill.scss";

import htmlLogo from "../../assets/images/HTML.png";
import cssLogo from "../../assets/images/CSS.png";
import jsLogo from "../../assets/images/JS.png";
import reactLogo from "../../assets/images/REACT.png";
import vscodelogo from "../../assets/images/vscode_logo.png";
import gitlogo from "../../assets/images/github_logo.png";
import maclogo from "../../assets/images/apple_logo.png";
import linuxlogo from "../../assets/images/linux_logo.png";
import windowslogo from "../../assets/images/windows_logo.png";
import nodeJsLogo from "../../assets/images/nodejs_logo.png";
import sassLogo from "../../assets/images/Sass_logo.png";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const Skill = ({ setClickOpenNav }) => {
  return (
    <div className="skillContainer" id="skill" onClick={() => setClickOpenNav(false)}>
      <Mobile>
        <div className="blocBehindHeader"></div>
        <h3 className="subTitle">Compétences</h3>
        <div className="PagesofCards">
          <div className="cardsPage_skill">
            <h3 className="cardsTitle">Langages de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={htmlLogo}
                    alt="Moi"
                  />
                  Html
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={cssLogo}
                    alt="Moi"
                  />
                  CSS
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={sassLogo}
                    alt="Moi"
                  />
                  SASS
                </li>
                <li className="list_lang_card">
                  <img className="list_lang_logo_card" src={jsLogo} alt="Moi" />
                  Java Script
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={reactLogo}
                    alt="Moi"
                  />
                  React
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={nodeJsLogo}
                    alt="Moi"
                  />
                  Node.js
                </li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_skill">
            <h3 className="cardsTitle">Outils de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={vscodelogo}
                    alt="Moi"
                  />
                  VSCode
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={gitlogo}
                    alt="Moi"
                  />
                  Git
                </li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_skill">
            <h3 className="cardsTitle">Environnements de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={maclogo}
                    alt="Moi"
                  />
                  Mac
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={linuxlogo}
                    alt="Moi"
                  />
                  Linux
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={windowslogo}
                    alt="Moi"
                  />
                  WSL
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className="blocBehindHeader_tablet"></div>
        <h3 className="subTitle">Compétences</h3>
        <div className="PagesofCards">
          <div className="cardsPage_skill_tablet">
            <h3 className="cardsTitle">Langages de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={htmlLogo}
                    alt="Moi"
                  />
                  Html
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={cssLogo}
                    alt="Moi"
                  />
                  CSS
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={sassLogo}
                    alt="Moi"
                  />
                  SASS
                </li>
                <li className="list_lang_card">
                  <img className="list_lang_logo_card" src={jsLogo} alt="Moi" />
                  Java Script
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={reactLogo}
                    alt="Moi"
                  />
                  React
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={nodeJsLogo}
                    alt="Moi"
                  />
                  Node.js
                </li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_skill_tablet">
            <h3 className="cardsTitle">Outils de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={vscodelogo}
                    alt="Moi"
                  />
                  VSCode
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={gitlogo}
                    alt="Moi"
                  />
                  Git
                </li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_skill_tablet">
            <h3 className="cardsTitle">Environnements de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={maclogo}
                    alt="Moi"
                  />
                  Mac
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={linuxlogo}
                    alt="Moi"
                  />
                  Linux
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={windowslogo}
                    alt="Moi"
                  />
                  WSL
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="blocBehindHeader_desktop"></div>
        <h3 className="subTitle">Compétences</h3>
        <div className="PagesofCards_desktop">
          <div className="cardsPage_skill_desktop">
            <h3 className="cardsTitle">Langages de développement</h3>
            <div className="cardsContain_Description_desktop">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={htmlLogo}
                    alt="Moi"
                  />
                  Html
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={cssLogo}
                    alt="Moi"
                  />
                  CSS
                </li>
                <li className="list_lang_card_desktop">
                  <img
                    className="list_lang_logo_card_desktop"
                    src={sassLogo}
                    alt="Moi"
                  />
                  SASS
                </li>
              </ul>
              <ul className="List_card">
                <li className="list_lang_card">
                  <img className="list_lang_logo_card" src={jsLogo} alt="Moi" />
                  Java Script
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={reactLogo}
                    alt="Moi"
                  />
                  React
                </li>
                <li className="list_lang_card_desktop">
                  <img
                    className="list_lang_logo_card_desktop"
                    src={nodeJsLogo}
                    alt="Moi"
                  />
                  Node.js
                </li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_skill_desktop">
            <h3 className="cardsTitle">Outils de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={vscodelogo}
                    alt="Moi"
                  />
                  VSCode
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={gitlogo}
                    alt="Moi"
                  />
                  Git
                </li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_skill_desktop">
            <h3 className="cardsTitle">Environnements de développement</h3>
            <div className="cardsContain_Description">
              <ul className="List_card">
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={maclogo}
                    alt="Moi"
                  />
                  Mac
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={linuxlogo}
                    alt="Moi"
                  />
                  Linux
                </li>
                <li className="list_lang_card">
                  <img
                    className="list_lang_logo_card"
                    src={windowslogo}
                    alt="Moi"
                  />
                  WSL
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Desktop>
    </div>
  );
};

export default Skill;