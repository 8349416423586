import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";

import axios from 'axios';

import NavHome from "../NavHome";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import circleLogoMj from '../../assets/images/circle_button_mj.png'
import CvPdf from '../../assets/pdf/CV_MANDIN_01_22_comp.pdf'
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import './contact.scss';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const Contact = ({ clickOpenNav, handleClickNav,
  setClickOpenNav, }) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [messageSucces, setMessageSucces] = useState(false)
  const [messageLoader, setMessageLoader] = useState(false)
  const [hideSendButton, setHideSendButton] = useState(true)
  const [errorMessage, setErrorMessage] = useState(false)
  const onSubmit = (data) => {

    setMessageLoader(true)
    setHideSendButton(false)


    axios.post('https://www.api.admin.mandinjeremie.fr/posts', {
      name: data.name,
      company: data.company,
      phone: data.phone,
      email: data.email,
      message: data.message
    })
      .then(function (response) {
        // handle success
        reset();
        setMessageSucces(true);
      })
      .catch(function (error) {
        // handle error
        setErrorMessage(true)
      })
      .then(function () {
        setMessageLoader(false)
      });
  };

  return (
    <div className="contactContainer">
      {clickOpenNav && (
        <div>
          <NavHome handleClickNav={handleClickNav} />
        </div>
      )}
      <Mobile>
        <div className="blocBehindHeader"></div>
        <h3 className="subTitle">Contact</h3>
        <div className="PagesofCards">
          <div className="contactFormBloc">
            <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
              <label>Nom</label>
              <input className="inputForm" {...register("name", { required: true })} />
              {errors.name && <span className="errorsMessage">Veuillez indiquer votre nom</span>}
              <label>Société</label>
              <input className="inputForm" {...register("company", { required: true })} />
              {errors.company && <span className="errorsMessage">Veuillez indiquer le nom de votre société</span>}
              <label>Téléphone</label>
              <input className="inputForm" {...register("phone", { required: true })} />
              {errors.phone && <span className="errorsMessage">Veuillez indiquer votre numéro de téléphone</span>}
              <label>Email</label>
              <input className="inputForm" type="email" {...register("email", { required: true })} />
              {errors.email && <span className="errorsMessage">Veuillez indiquer votre mail</span>}
              <label>Votre message</label>
              <textarea rows="4" className="inputFormMessage" {...register("message", { required: true })} />
              {errors.message && <span className="errorsMessage">Veuillez indiquer votre message</span>}
              {hideSendButton && (
                <input type="submit" className="inputFormSubmit" />
              )}
              {messageLoader && (
                <div className="loader_desktop"><img className="logoLoader_desktop" src={circleLogoMj} alt="un cercle avec le logo mj à l'interieur pour représenter le zero du 404" /><span className="formMessageLoader">Envoie du message en cours...</span></div>
              )}
              {errorMessage && (
                <span className="form-wrong-message">Oups ! Une erreur est survenue ! Veuillez réessayer ou m'envoyer directement un mail.</span>
              )}
              {messageSucces && (
                <div className="form-message">Merci, votre message a bien été envoyé !</div>
              )}
            </form>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle">Mes Coordonnées</h3>
            <div className="cardsContainBloc">
              <div className="cardsContain">
                <a className="cardCom" href="mailto:contact@mandinjeremie.fr">
                  <MailIcon />
                  <p>contact@mandinjeremie.fr</p>
                </a>
              </div>
              <div className="cardsContain">
                <a className="cardCom" href="https://www.linkedin.com/in/j%C3%A9r%C3%A9mie-mandin-908a9a1a8/" rel='noreferrer' target="_blank">
                  <LinkedInIcon />
                  <p>Profil LinkedIn</p>
                </a>
              </div>
              <div className="cardsContain">
                <a className="cardCom" href="tel:+33676691562">
                  <CallIcon />
                  <p>+33676691562</p>
                </a>
              </div>
              <a className="downloadPdf" href={CvPdf} download="CV_MANDIN_Jérémie.pdf">
                Télécharger mon CV au format PDF
              </a>
            </div>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className="blocBehindHeader_tablet"></div>
        <h3 className="subTitle">Contact</h3>
        <div className="PagesofCards">
          <div className="contactFormBloc">
            <form className="contactForm" onSubmit={handleSubmit(onSubmit)}>
              <label>Nom</label>
              <input className="inputForm" {...register("name", { required: true })} />
              {errors.name && <span className="errorsMessage">Veuillez indiquer votre nom</span>}
              <label>Société</label>
              <input className="inputForm" {...register("company", { required: true })} />
              {errors.company && <span className="errorsMessage">Veuillez indiquer le nom de votre société</span>}
              <label>Téléphone</label>
              <input className="inputForm" {...register("phone", { required: true })} />
              {errors.phone && <span className="errorsMessage">Veuillez indiquer votre numéro de téléphone</span>}
              <label>Email</label>
              <input className="inputForm" type="email" {...register("email", { required: true })} />
              {errors.email && <span className="errorsMessage">Veuillez indiquer votre mail</span>}
              <label>Votre message</label>
              <textarea rows="4" className="inputFormMessage" {...register("message", { required: true })} />
              {errors.message && <span className="errorsMessage">Veuillez indiquer votre message</span>}
              {hideSendButton && (
                <input type="submit" className="inputFormSubmit" />
              )}
              {messageLoader && (
                <div className="loader_desktop"><img className="logoLoader_desktop" src={circleLogoMj} alt="un cercle avec le logo mj à l'interieur pour représenter le zero du 404" /><span className="formMessageLoader">Envoie du message en cours...</span></div>
              )}
              {errorMessage && (
                <span className="form-wrong-message">Oups ! Une erreur est survenue ! Veuillez réessayer ou m'envoyer directement un mail.</span>
              )}
              {messageSucces && (
                <div className="form-message">Merci, votre message a bien été envoyé !</div>
              )}
            </form>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle">Mes Coordonnées</h3>
            <div className="cardsContainBloc">
              <div className="cardsContain">
                <a className="cardCom" href="mailto:contact@mandinjeremie.fr">
                  <MailIcon />
                  <p>contact@mandinjeremie.fr</p>
                </a>
              </div>
              <div className="cardsContain">
                <a className="cardCom" href="https://www.linkedin.com/in/j%C3%A9r%C3%A9mie-mandin-908a9a1a8/" rel='noreferrer' target="_blank">
                  <LinkedInIcon />
                  <p>Profil LinkedIn</p>
                </a>
              </div>
              <div className="cardsContain">
                <a className="cardCom" href="tel:+33676691562">
                  <CallIcon />
                  <p>+33676691562</p>
                </a>
              </div>
              <a className="downloadPdf" href={CvPdf} download="CV_MANDIN_Jérémie.pdf">
                Télécharger mon CV au format PDF
              </a>
            </div>
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="blocBehindHeader_desktop"></div>
        <h3 className="subTitle">Contact</h3>
        <div className="PagesofCards_desktop">
          <form className="contactForm_desktop" onSubmit={handleSubmit(onSubmit)}>
            <label>Nom</label>
            <input className="inputForm" {...register("name", { required: true })} />
            {errors.name && <span className="errorsMessage">Veuillez indiquer votre nom</span>}
            <label>Société</label>
            <input className="inputForm" {...register("company", { required: true })} />
            {errors.company && <span className="errorsMessage">Veuillez indiquer le nom de votre société</span>}
            <label>Téléphone</label>
            <input className="inputForm" {...register("phone", { required: true })} />
            {errors.phone && <span className="errorsMessage">Veuillez indiquer votre numéro de téléphone</span>}
            <label>Email</label>
            <input className="inputForm" type="email" {...register("email", { required: true })} />
            {errors.email && <span className="errorsMessage">Veuillez indiquer votre mail</span>}
            <label>Votre message</label>
            <textarea rows="4" className="inputFormMessage" {...register("message", { required: true })} />
            {errors.message && <span className="errorsMessage">Veuillez indiquer votre message</span>}
            {hideSendButton && (
              <input type="submit" className="inputFormSubmit" />
            )}
            {messageLoader && (
              <div className="loader_desktop"><img className="logoLoader_desktop" src={circleLogoMj} alt="un cercle avec le logo mj à l'interieur pour représenter le zero du 404" /><span className="formMessageLoader">Envoie du message en cours...</span></div>
            )}
            {errorMessage && (
              <span className="form-wrong-message">Oups ! Une erreur est survenue ! Veuillez réessayer ou m'envoyer directement un mail.</span>
            )}
            {messageSucces && (
              <div className="form-message">Merci, votre message a bien été envoyé !</div>
            )}
          </form>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle">Mes Coordonnées</h3>
            <div className="cardsContainBloc">
              <div className="cardsContain">
                <a className="cardCom" href="mailto:contact@mandinjeremie.fr">
                  <MailIcon />
                  <p>contact@mandinjeremie.fr</p>
                </a>
              </div>
              <div className="cardsContain">
                <a className="cardCom" href="https://www.linkedin.com/in/j%C3%A9r%C3%A9mie-mandin-908a9a1a8/" rel='noreferrer' target="_blank">
                  <LinkedInIcon />
                  <p>Profil LinkedIn</p>
                </a>
              </div>
              <div className="cardsContain">
                <a className="cardCom" href="tel:+33676691562">
                  <CallIcon />
                  <p>+33676691562</p>
                </a>
              </div>
              <a className="downloadPdf" href={CvPdf} download="CV_MANDIN_Jérémie.pdf">
                Télécharger mon CV au format PDF
              </a>
            </div>
          </div>
        </div>
      </Desktop>
    </div>
  );
};

export default Contact;