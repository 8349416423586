import React from "react";
import { useMediaQuery } from "react-responsive";
import logoShort from "../../assets/images/logo_MJ_short.png"
import logoPing from "../../assets/images/pinguinMobile.png"
import rocketlogo from "../../assets/images/rocketfid.png"
import CodeIcon from '@material-ui/icons/Code';
import PetsIcon from '@material-ui/icons/Pets';
import PowerIcon from '@material-ui/icons/Power';
import LocalBarIcon from '@material-ui/icons/LocalBar';

import './xp.scss';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const CardsXp = ({ setClickOpenNav }) => {
  return (
    <div className="xpContainer" id="xp" onClick={() => setClickOpenNav(false)}>
      <Mobile>
        <div className="blocBehindHeader"></div>
        <h3 className="subTitle">Expériences</h3>
        <div className="PagesofCards">
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <a href="https://www.rocketfid.com/fr/" rel='noreferrer' target="_blank"><h3 className="cardsTitle">Rocketfid</h3></a>
              <img className="logoCV" src={rocketlogo} alt="logo du site" />
            </div>
            <div className="cardsContain">Octobre 2021 à Janvier 2022</div>
            <div className="cardsContain">
              Développeur front-end en JavaScript, Nunjucks, CSS et découverte de VueJS sur un widget de gamification.
            </div>
            <div className="cardsContain">
              Maintenance corrective ainsi qu'amélioration et création de nouvelles features dont le développement d’une extension sur une page admin de marketplace d'un site e-commerce. Environement: Ubuntu WSL.
            </div>
          </div>
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <a href="https://www.pingotivation.fr/" rel='noreferrer' target="_blank"><h3 className="cardsTitle">Pingotivation.fr</h3></a>
              <img className="logoCV" src={logoPing} alt="logo du site" />
            </div>
            <div className="cardsContain">En Mai 2021 en téléprésentiel et hors formation</div>
            <div className="cardsContain">
              Reprise du projet Omotive pour une mise en production. Quelques finitions du responsive, changement du nom de l'application et amélioration UI.
            </div>
            <div className="cardsContain">En Janvier 2021 en téléprésentiel.</div>
            <div className="cardsContain">
              Projet de fin de formation à l'école O'clock développé en équipe avec un front React et un back API Wordpress baptisé Omotive. Étant développeur front nous avons élaboré un cahier des charges avec des wireframes, travaillé avec la méthode AGIL. Notre projet utilise React-Redux, JS, HTML (JSX) et du SASS.
            </div>
          </div>
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <h3 className="cardsTitle">CV Mandin Jérémie</h3>
              <img className="logoCV" src={logoShort} alt="logo du site" />
            </div>
            <div className="cardsContain">En Mars 2021 à mon domicile</div>
            <div className="cardsContain">
              Développement de ce CV. Les langages utilisés sont React, JS, HTML (JSX), du SASS pour le front et Node Js, mysql, nodemailer pour le back.
            </div>
          </div>
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <h3 className="cardsTitle">Stagiaire Web Developpeur</h3>
              <CodeIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">En Juillet 2020 à Bordeaux.</div>
            <div className="cardsContain">
              A l'Addition en tant que developpeur React.
            </div>
          </div>
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <h3 className="cardsTitle">Agent Cynophile de Sécurité</h3>
              <PetsIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2014 à 2019 :</div>
            <div className="cardsContain">
              Dans différentes sociétés privées, dernièrement chez RVF Sécurité pendant
              3 ans assurant des missions dans la Gironde.
            </div>
          </div>
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <h3 className="cardsTitle">Electricien en bâtiment</h3>
              <PowerIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2012 à 2013 :</div>
            <div className="cardsContain">
              Chez Bourdouleix dans la Gironde.
            </div>
          </div>
          <div className="cardsPage">
            <div className="titleCardWithLogo_Mobile">
              <h3 className="cardsTitle">Différents métiers de services</h3>
              <LocalBarIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2007 à 2012 :</div>
            <div className="cardsContain">
              <ul>
                <li>Serveur</li>
                <li>Réceptioniste</li>
                <li>Barman</li>
                <li>Livreur de pizza</li>
                <li>Vendeur</li>
              </ul>
            </div>
            <div className="cardsContain">
              A Monaco, Londres, Val Louron dans les Pyrénées et en Gironde.
            </div>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className="blocBehindHeader_tablet"></div>
        <h3 className="subTitle">Expériences</h3>
        <div className="PagesofCards">
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <a href="https://www.rocketfid.com/fr/" rel='noreferrer' target="_blank"><h3 className="cardsTitle">Rocketfid</h3></a>
              <img className="logoCV" src={rocketlogo} alt="logo du site" />
            </div>
            <div className="cardsContain">Octobre 2021 à Janvier 2022</div>
            <div className="cardsContain">
              Développeur front-end en JavaScript, Nunjucks, CSS et découverte de VueJS sur un widget de gamification.
            </div>
            <div className="cardsContain">
              Maintenance corrective ainsi qu'amélioration et création de nouvelles features dont le développement d’une extension sur une page admin de marketplace d'un site e-commerce. Environement: Ubuntu WSL.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <a href="https://www.pingotivation.fr/" rel='noreferrer' target="_blank"><h3 className="cardsTitle">Pingotivation.fr</h3></a>
              <img className="logoCV" src={logoPing} alt="logo du site" />
            </div>
            <div className="cardsContain">En Mai 2021 en téléprésentiel et hors formation</div>
            <div className="cardsContain">
              Reprise du projet Omotive pour une mise en production. Quelques finitions du responsive, changement du nom de l'application et amélioration UI.
            </div>
            <div className="cardsContain">En Janvier 2021 en téléprésentiel.</div>
            <div className="cardsContain">
              Projet de fin de formation à l'école O'clock développé en équipe avec un front React et un back API Wordpress baptisé Omotive. Étant développeur front nous avons élaboré un cahier des charges avec des wireframes, travaillé avec la méthode AGIL. Notre projet utilise React-Redux, JS, HTML (JSX) et du SASS.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <h3 className="cardsTitle">CV Mandin Jérémie</h3>
              <img className="logoCV" src={logoShort} alt="logo du site" />
            </div>
            <div className="cardsContain">En Mars 2021 à mon domicile</div>
            <div className="cardsContain">
              Développement de ce CV. Les langages utilisés sont React, JS, HTML (JSX), du SASS pour le front et Node Js, mysql, nodemailer pour le back.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <h3 className="cardsTitle">Stagiaire Web Developpeur</h3>
              <CodeIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">En Juillet 2020 à Bordeaux.</div>
            <div className="cardsContain">
              A l'Addition en tant que developpeur React.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <h3 className="cardsTitle">Agent Cynophile de Sécurité</h3>
              <PetsIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2014 à 2019 :</div>
            <div className="cardsContain">
              Dans différentes sociétés privées, dernièrement chez RVF Sécurité pendant
              3 ans assurant des missions dans la Gironde.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <h3 className="cardsTitle">Electricien en bâtiment</h3>
              <PowerIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2012 à 2013 :</div>
            <div className="cardsContain">
              Chez Bourdouleix dans la Gironde.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <div className="titleCardWithLogo_Tablet">
              <h3 className="cardsTitle">Différents métiers de services</h3>
              <LocalBarIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2007 à 2012 :</div>
            <div className="cardsContain">
              <ul>
                <li>Serveur</li>
                <li>Réceptioniste</li>
                <li>Barman</li>
                <li>Livreur de pizza</li>
                <li>Vendeur</li>
              </ul>
            </div>
            <div className="cardsContain">
              A Monaco, Londres, Val Louron dans les Pyrénées et en Gironde.
            </div>
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="blocBehindHeader_desktop"></div>
        <h3 className="subTitle">Expériences</h3>
        <div className="PagesofCards">
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <a href="https://www.rocketfid.com/fr/" rel='noreferrer' target="_blank"><h3 className="cardsTitle">Rocketfid</h3></a>
              <img className="logoCV" src={rocketlogo} alt="logo du site" />
            </div>
            <div className="cardsContain">Octobre 2021 à Janvier 2022</div>
            <div className="cardsContain">
              Développeur front-end en JavaScript, Nunjucks, CSS et découverte de VueJS sur un widget de gamification.
            </div>
            <div className="cardsContain">
              Maintenance corrective ainsi qu'amélioration et création de nouvelles features dont le développement d’une extension sur une page admin de marketplace d'un site e-commerce. Environement: Ubuntu WSL.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <a href="https://www.pingotivation.fr/" rel='noreferrer' target="_blank"><h3 className="cardsTitle">Pingotivation.fr</h3></a>
              <img className="logoCV" src={logoPing} alt="logo du site" />
            </div>
            <div className="cardsContain">En Mai 2021 en téléprésentiel et hors formation</div>
            <div className="cardsContain">
              Reprise du projet Omotive pour une mise en production. Quelques finitions du responsive, changement du nom de l'application et amélioration UI.
            </div>
            <div className="cardsContain">En Janvier 2021 en téléprésentiel.</div>
            <div className="cardsContain">
              Projet de fin de formation à l'école O'clock développé en équipe avec un front React et un back API Wordpress baptisé Omotive. Étant développeur front nous avons élaboré un cahier des charges avec des wireframes, travaillé avec la méthode AGIL. Notre projet utilise React-Redux, JS, HTML (JSX) et du SASS.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <h3 className="cardsTitle">CV Mandin Jérémie</h3>
              <img className="logoCV" src={logoShort} alt="logo du site" />
            </div>
            <div className="cardsContain">En Mars 2021 à mon domicile</div>
            <div className="cardsContain">
              Développement de ce CV. Les langages utilisés sont React, JS, HTML (JSX), du SASS pour le front et Node Js, mysql, nodemailer pour le back.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <h3 className="cardsTitle">Stagiaire Web Developpeur</h3>
              <CodeIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">En Juillet 2020 à Bordeaux.</div>
            <div className="cardsContain">
              A l'Addition en tant que developpeur React.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <h3 className="cardsTitle">Agent Cynophile de Sécurité</h3>
              <PetsIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2014 à 2019 :</div>
            <div className="cardsContain">
              Dans différentes sociétés privées, dernièrement chez RVF Sécurité pendant
              3 ans assurant des missions dans la Gironde.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <h3 className="cardsTitle">Electricien en bâtiment</h3>
              <PowerIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2012 à 2013 :</div>
            <div className="cardsContain">
              Chez Bourdouleix dans la Gironde.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <div className="titleCardWithLogo">
              <h3 className="cardsTitle">Différents métiers de services</h3>
              <LocalBarIcon style={{ fontSize: 40 }} />
            </div>
            <div className="cardsContain">De 2007 à 2012 :</div>
            <div className="cardsContain">
              <ul>
                <li>Serveur</li>
                <li>Réceptioniste</li>
                <li>Barman</li>
                <li>Livreur de pizza</li>
                <li>Vendeur</li>
              </ul>
            </div>
            <div className="cardsContain">
              A Monaco, Londres, Val Louron dans les Pyrénées et en Gironde.
            </div>
          </div>
        </div>
      </Desktop>
    </div>
  );
};
export default CardsXp;