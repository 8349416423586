import React from 'react';
import logo4orange from '../../assets/images/4_logo_orange.png';
import logo4jaune from '../../assets/images/4_logo_Jaune.png';
import circleLogoMj from '../../assets/images/circle_button_mj.png'

import './page404.scss';

const Page404 = () => {

  return (
    <div className="page404Container">
      <div className="blocBehindHeader"></div>
      <h3 className="subTitle">Oups !</h3>
      <div className="bloc404">
        <div className="logo404">
          <img className="logoNumber" src={logo4orange} alt="4 for 404 orange" />
          <img className="logo0" src={circleLogoMj} alt="un cercle avec le logo mj à l'interieur pour représenter le zero du 404" />
          <img className="logoNumber" src={logo4jaune} alt="4 for 404 jaune" />
        </div>
        <h3 className="content404">La page demandée</h3>
        <h3 className="content404">est perdue dans mon code !</h3>
      </div>
    </div>

  )
}

export default Page404;