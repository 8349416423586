import React from "react";
import { useMediaQuery } from "react-responsive";


import { NavLink } from 'react-router-dom';
import logoShort from "../../assets/images/logo_MJ_short.png"
import logoMenuBurger from "../../assets/images/logo_burger_menu_color.png"
import logoContact from "../../assets/images/logo_contact_color.png"


import './headercontact.scss';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const HeaderContact = ({ handleClickOpenNav, handleClickLogo }) => {

  return (
    <div>
      <Mobile>
        <header className="header" >
          <NavLink
            className="headerLogoContent"
            to="/contact"
          >
            <img className="logoMenuBurger" src={logoContact} alt="logo du site" />
          </NavLink>
          <NavLink
            className="headerLogoContent"
            to="/"
          >
            <img className="logoShort" src={logoShort} alt="logo du site" />
          </NavLink>
          <NavLink
            className="headerLogoContent"
            to="/"
          >
            <img className="logoMenuBurger" src={logoMenuBurger} alt="logo du site" onClick={handleClickOpenNav} />
          </NavLink>
        </header>
      </Mobile>
      <Tablet>
        <header className="header_contact_tablet" >
          <NavLink
            className="headerLogoContent_contact_tablet"
            to="/contact"
          >
            <img className="logoMenuBurger_contact_tablet" src={logoContact} alt="logo du site" />
          </NavLink>
          <NavLink
            className="headerLogoContent_contact_tablet"
            to="/"
          >
            <img className="logoShort_contact_tablet" src={logoShort} alt="logo du site" />
          </NavLink>
          <NavLink
            className="headerLogoContent_contact_tablet"
            to="/"
          >
            <img className="logoMenuBurger_contact_tablet" src={logoMenuBurger} alt="logo du site" />
          </NavLink>
        </header>
      </Tablet>
      <Desktop>
        <header className="header_contact_desktop" >
          <NavLink
            className="headerLogoContent_contact_desktop"
            to="/contact"
          >
            <img className="logoMenuBurger_contact_desktop" src={logoContact} alt="logo du site" />
          </NavLink>
          <NavLink
            className="headerLogoContent_contact_desktop"
            to="/"
          >
            <img className="logoShort_contact_desktop" src={logoShort} alt="logo du site" />
          </NavLink>
          <NavLink
            className="headerLogoContent_contact_desktop"
            to="/"
          >
            <img className="logoMenuBurger_contact_desktop" src={logoMenuBurger} alt="logo du site" />
          </NavLink>
        </header>
      </Desktop>
    </div>
  );
};

export default HeaderContact;