import React, { useState } from "react";
import Pages from "../Pages";


import './app.scss';



const App = () => {
  const [clickOpenNav, setClickOpenNav] = useState(false);

  const handleClickOpenNav = () => {
    if (clickOpenNav === false) {
      setClickOpenNav(true);
    } else setClickOpenNav(false);
  }
  const handleClickNav = () => {
    setClickOpenNav(false);
  }
  const handleClickLogo = () => {
    setClickOpenNav(false);
  }
  return (
    <div className="globalContainer">
      <Pages
        clickOpenNav={clickOpenNav}
        setClickOpenNav={setClickOpenNav}
        handleClickNav={handleClickNav}
        handleClickOpenNav={handleClickOpenNav}
        handleClickLogo={handleClickLogo}
      />
    </div>
  );
};

export default App;
