import React from "react";
import { useMediaQuery } from "react-responsive";

import htmlLogo from "../../assets/images/HTML.png";
import cssLogo from "../../assets/images/CSS.png";
import jsLogo from "../../assets/images/JS.png";
import reactLogoClassic from "../../assets/images/REACT.png";
import nodeJsLogo from "../../assets/images/nodejs_logo.png";
import sassLogo from "../../assets/images/Sass_logo.png";

import photoDeMoi from "../../assets/images/detourage_moi_02_desktop.png"
import photoDeMoiMobile from "../../assets/images/detourage_moi_02_mobile.png"

import NavHome from "../../components/NavHome";

import Description from "../../components/Description";
import Formation from "../../components/Formations";
import Skill from "../../components/Skill";
import CardsXp from "../../components/Xp";
import './home.scss'



const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const Home = ({
  clickOpenNav,
  ref,
  handleClickNav,
  setClickOpenNav,
}) => {

  return (
    <div>
      <Mobile>
        <div
          ref={ref}
          className="homePage"
          id="home"
          onClick={() => setClickOpenNav(false)}
        >
          <div className="blocBehindHeader_home"></div>
          <div className="blocHomePage_Tablet">
            <div className="clasicHomePage">
              <h1 className="the_title">Mandin Jérémie</h1>
              <h2 className="the_subTitle">Développeur Web</h2>
            </div>

            {clickOpenNav && (
              <div>
                <NavHome handleClickNav={handleClickNav} />
              </div>
            )}
            <div className="pict_home">
              <img src={photoDeMoiMobile} alt="Mandin Jérémie" className="picture" />
            </div>
          </div>
          <Skill setClickOpenNav={setClickOpenNav} />
          <CardsXp setClickOpenNav={setClickOpenNav} />
          <Formation setClickOpenNav={setClickOpenNav} />
          <Description setClickOpenNav={setClickOpenNav} />
        </div>
      </Mobile>
      <Tablet>
        <div
          ref={ref}
          className="homePage"
          id="home"
          onClick={() => setClickOpenNav(false)}
        >
          <div className="blocBehindHeader_home"></div>
          <div className="blocHomePage_Tablet">
            <div className="clasicHomePage">
              <h1 className="the_title">Mandin Jérémie</h1>
              <h2 className="the_subTitle_tablet">Développeur Web</h2>
            </div>

            {clickOpenNav && (
              <div>
                <NavHome handleClickNav={handleClickNav} />
              </div>
            )}
            <div className="pict_home">
              <img src={photoDeMoi} alt="Mandin Jérémie" className="picture" />
            </div>
          </div>
          <Skill setClickOpenNav={setClickOpenNav} />
          <CardsXp setClickOpenNav={setClickOpenNav} />
          <Formation setClickOpenNav={setClickOpenNav} />
          <Description setClickOpenNav={setClickOpenNav} />
        </div>
      </Tablet>
      <Desktop>
        <div
          ref={ref}
          className="homePage_desktop"
          id="home"
          onClick={() => setClickOpenNav(false)}
        >
          <div className="blocBehindHeader_home_desktop"></div>
          <div className="blocHomePage_desktop">
            <div className="clasicHomePage_desktop">
              <h1 className="the_title_desktop">Mandin Jérémie</h1>
              <h2 className="the_subTitle_desktop">Développeur Web</h2>
              <div className="Lists_Skill">
                <ul className="List_card_desktop">
                  <li className="list_lang_card_desktop">
                    <img
                      className="list_lang_logo_card_desktop"
                      src={htmlLogo}
                      alt="Html"
                    />
                  Html
                </li>
                  <li className="list_lang_card_desktop">
                    <img
                      className="list_lang_logo_card_desktop"
                      src={cssLogo}
                      alt="CSS"
                    />
                  CSS
                </li>
                  <li className="list_lang_card_desktop">
                    <img
                      className="list_lang_logo_card_desktop"
                      src={sassLogo}
                      alt="SASS"
                    />
                  SASS
                </li>
                </ul>
                <ul className="List_card_desktop">
                  <li className="list_lang_card_desktop">
                    <img className="list_lang_logo_card_desktop" src={jsLogo} alt="Java Script" />
                  Java Script
                </li>
                  <li className="list_lang_card_desktop">
                    <img
                      className="list_lang_logo_card_desktop"
                      src={reactLogoClassic}
                      alt="React"
                    />
                  React
                </li>
                  <li className="list_lang_card_desktop">
                    <img
                      className="list_lang_logo_card_desktop"
                      src={nodeJsLogo}
                      alt="Node.js"
                    />
                  Node.js
                </li>
                </ul>
              </div>
            </div>
            <div className="pict_home_desktop">
              <img src={photoDeMoi} alt="Mandin Jérémie" className="picture_desktop" />
            </div>
          </div>
          <Skill setClickOpenNav={setClickOpenNav} />
          <CardsXp setClickOpenNav={setClickOpenNav} />
          <Formation setClickOpenNav={setClickOpenNav} />
          <Description setClickOpenNav={setClickOpenNav} />
        </div>
      </Desktop>

    </div>
  );
};

export default Home;