import React from "react";
import { useMediaQuery } from "react-responsive";

import './description.scss';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const Description = ({ setClickOpenNav }) => {
  return (
    <div
      className="descriptionContainer"
      id="description"
      onClick={() => setClickOpenNav(false)}
    >
      <Mobile>
        <div className="blocBehindHeader"></div>
        <h3 className="subTitle">Qui suis-je ?</h3>
        <div className="PagesofCards">
          <div className="cardsPage">
            <h3 className="cardsTitle">M.Mandin Jérémie</h3>
            <div className="cardsContain">
              Né le 10 Novembre 1990 à Menton (06).
            </div>
            <div className="cardsContain">
              Résident à Cussac-Fort-Medoc (33460).
            </div>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle">À propos de moi</h3>
            <div className="cardsContain">
              Issu de plusieurs métiers dans différents domaines et avec une passion
              depuis très jeune pour l’informatique, j’ai décidé à mes 29 ans de me
              reconvertir dans le web. Développeur Front-End spécialisé en React, sorti de
              l’école o'clock en mars 2020, j’ai jusqu’à ce jour tenté de confirmer et valider
              mes expériences via des projets personnels, un stage et un nouveau passage
              en école en vue de devenir un professionnel compétent. J’ai encore beaucoup
              de choses à apprendre et je suis déterminé à vivre de ce qui me passionne.
            </div>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle">Langues vivantes</h3>
            <div className="cardsContain">
              <ul>
                <li>Anglais : Courant</li>
                <li>Italien: Débutant</li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_loisirs">
            <h3 className="cardsTitle">Mes Loisirs</h3>
            <div className="cardsContain_loisirs">
              <div className="loisirs">Golf</div>
              <div className="loisirs">Vidéaste amateur</div>
              <div className="loisirs">SnowBoard</div>
              <div className="loisirs">WakeBoard</div>
              <div className="loisirs">VTT</div>
              <div className="loisirs">Guitariste</div>
              <div className="loisirs">Jeux Vidéo</div>
              <div className="loisirs">Voyages</div>
            </div>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className="blocBehindHeader_tablet"></div>
        <h3 className="subTitle">Qui suis-je ?</h3>
        <div className="PagesofCards">
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle">M.Mandin Jérémie</h3>
            <div className="cardsContain">
              Né le 10 Novembre 1990 à Menton (06).
            </div>
            <div className="cardsContain">
              Résident à Cussac-Fort-Medoc (33460).
            </div>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle">À propos de moi</h3>
            <div className="cardsContain">
              Issu de plusieurs métiers dans différents domaines et avec une passion
              depuis très jeune pour l’informatique, j’ai décidé à mes 29 ans de me
              reconvertir dans le web. Développeur Front-End spécialisé en React, sorti de
              l’école o'clock en mars 2020, j’ai jusqu’à ce jour tenté de confirmer et valider
              mes expériences via des projets personnels, un stage et un nouveau passage
              en école en vue de devenir un professionnel compétent. J’ai encore beaucoup
              de choses à apprendre et je suis déterminé à vivre de ce qui me passionne.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle">Langues vivantes</h3>
            <div className="cardsContain">
              <ul>
                <li>Anglais : Courant</li>
                <li>Italien: Débutant</li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_loisirs_tablet">
            <h3 className="cardsTitle">Mes Loisirs</h3>
            <div className="cardsContain_loisirs">
              <div className="loisirs">Golf</div>
              <div className="loisirs">Vidéaste amateur</div>
              <div className="loisirs">SnowBoard</div>
              <div className="loisirs">WakeBoard</div>
              <div className="loisirs">VTT</div>
              <div className="loisirs">Guitariste</div>
              <div className="loisirs">Jeux Vidéo</div>
              <div className="loisirs">Voyages</div>
            </div>
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="blocBehindHeader_desktop"></div>
        <h3 className="subTitle">Qui suis-je ?</h3>
        <div className="PagesofCards">
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle">M.Mandin Jérémie</h3>
            <div className="cardsContain">
              Né le 10 Novembre 1990 à Menton (06).
            </div>
            <div className="cardsContain">
              Résident à Cussac-Fort-Medoc (33460).
            </div>
          </div>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle">À propos de moi</h3>
            <div className="cardsContain">
              Issu de plusieurs métiers dans différents domaines et avec une passion
              depuis très jeune pour l’informatique, j’ai décidé à mes 29 ans de me
              reconvertir dans le web. Développeur Front-End spécialisé en React, sorti de
              l’école o'clock en mars 2020, j’ai jusqu’à ce jour tenté de confirmer et valider
              mes expériences via des projets personnels, un stage et un nouveau passage
              en école en vue de devenir un professionnel compétent. J’ai encore beaucoup
              de choses à apprendre et je suis déterminé à vivre de ce qui me passionne.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle">Langues vivantes</h3>
            <div className="cardsContain">
              <ul>
                <li>Anglais : Courant</li>
                <li>Italien: Débutant</li>
              </ul>
            </div>
          </div>
          <div className="cardsPage_loisirs_desktop">
            <h3 className="cardsTitle">Mes Loisirs</h3>
            <div className="cardsContain_loisirs">
              <div className="loisirs">Golf</div>
              <div className="loisirs">Vidéaste amateur</div>
              <div className="loisirs">SnowBoard</div>
              <div className="loisirs">WakeBoard</div>
              <div className="loisirs">VTT</div>
              <div className="loisirs">Guitariste</div>
              <div className="loisirs">Jeux Vidéo</div>
              <div className="loisirs">Voyages</div>
            </div>
          </div>
        </div>
      </Desktop>
    </div>
  );
};
export default Description;