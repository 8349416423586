import React from "react";
import { useMediaQuery } from "react-responsive";

import oclocklogo from "../../assets/images/K81NHwiA_400x400.jpg";
import stgervlogo from "../../assets/images/logo combrailles.jpg";
import lthmlogo from "../../assets/images/Logo-LTHM_900x900.jpg";
import ssiaplogo from "../../assets/images/logo-formation-ssiap1-300x300.png";
import sstlogo from "../../assets/images/Sauveteur_secouriste_du_travail_Logo.svg.png";

import './formation.scss';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 150, maxWidth: 767 });
  return isMobile ? children : null;
};
/*
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
*/

const Formation = ({ setClickOpenNav }) => {
  return (
    <div className="FormationContainer" id="formation" onClick={() => setClickOpenNav(false)}>
      <Mobile>
        <div className="blocBehindHeader"></div>
        <h3 className="subTitle">Mes Formations</h3>
        <div className="PagesofCards">
          <div className="cardsPage">
            <h3 className="cardsTitle">Professionnalisation Développeur Web React</h3>
            <div className="cardsContain">De Décembre 2020 à Février 2021</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://oclock.io/formations/developpeur-react"
              >
                <img className="logo_formations" src={oclocklogo} alt="Moi" />
                O'clock
              </a>
            </div>
            <div className="cardsContain">
              À O’clock, école de développement web en téléprésentiel - Labellisée Grande Ecole du Numérique.
            </div>
            <div className="cardsContain">
              Titre Professionnel de Développeur Web et Web Mobile,
              niveau V (bac+2).
            </div>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle"> Développeur Web</h3>
            <div className="cardsContain">De Octobre 2019 à Mars 2020</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://oclock.io/formations/developpeur-web"
              >
                <img className="logo_formations" src={oclocklogo} alt="Moi" />
                O'clock
              </a>
            </div>
            <div className="cardsContain">
              À O’clock, école de développement web en téléprésentiel - Labellisée Grande Ecole du Numérique.
            </div>
            <div className="cardsContain">
              700h intensives
            </div>
            <div className="cardsContain">
              <ul>
                <li>3 mois de socle : HTML5/CSS3/PHP/Javascript</li>
                <li>1 mois de spécialisation React</li>
                <li>1 mois de projet</li>
              </ul>
            </div>
            <div className="cardsContain">
              Titre Professionnel de Développeur Web et Web Mobile, niveau V
              (bac+2) (non présenté).
            </div>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle"> Agent Cynophile de Sécurité</h3>
            <div className="cardsContain">De Décembre 2013 à Mai 2014</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://metiers-chien-animalerie.fr/formation-adulte/formations-longues/agent-cynophile-de-securite"
              >
                <img className="logo_formations" src={stgervlogo} alt="Moi" />
                ACS Lycée des Combrailles
              </a>
            </div>
            <div className="cardsContain">
              Au C.F.P.P.A de Blanquefort. Formations de Saint Gervais d'Auvergne.
            </div>
            <div className="cardsContain">
              Titre Professionnel Agent Cynophile de Sécurité (niveau BEP).
            </div>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle"> Electricien</h3>
            <div className="cardsContain"> De 2005 à 2007 :</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://lycee-technique.gouv.mc/A-propos-de-l-etablissement/Sections"
              >
                <img className="logo_formations" src={lthmlogo} alt="Moi" />
                L.T.H.Monaco
              </a>
            </div>
            <div className="cardsContain">
              Au Lycée Technique et Hôtelier de Monaco.
            </div>
            <div className="cardsContain">
              BEP Métier de l’électrotechnique.
            </div>
          </div>
          <div className="cardsPage">
            <h3 className="cardsTitle">
              Agent de sécurité incendie et sauveteur secouriste
            </h3>
            <div className="cardsContain">Valide jusqu'en 2021</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://www.pompiers.fr/grand-public/devenir-sapeur-pompier/metiers-surete-et-securite/diplome-service-de-securite-incendie"
              >
                <img className="logo_formations" src={ssiaplogo} alt="Moi" />
                SSIAP
              </a>
              <a
                className="link_logo_formations"
                href="http://www.inrs.fr/services/formation/publics/sauveteur-secouriste.html"
              >
                <img className="logo_formations" src={sstlogo} alt="Moi" />
                INRS - SST
              </a>
            </div>
            <div className="cardsContain">
              Agent S.S.I.A.P 1 ( service de sécurité incendie et d’assistance à
              personnes niveau 1 ) et S.S.T ( sauveteur secouriste du travail ) à ce
              jour recyclés.
            </div>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className="blocBehindHeader_tablet"></div>
        <h3 className="subTitle">Mes Formations</h3>
        <div className="PagesofCards">
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle">Professionnalisation Développeur Web React</h3>
            <div className="cardsContain">De Décembre 2020 à Février 2021</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://oclock.io/formations/developpeur-react"
              >
                <img className="logo_formations" src={oclocklogo} alt="Moi" />
                O'clock
              </a>
            </div>
            <div className="cardsContain">
              À O’clock, école de développement web en téléprésentiel - Labellisée Grande Ecole du Numérique.
            </div>
            <div className="cardsContain">
              Titre Professionnel de Développeur Web et Web Mobile,
              niveau V (bac+2).
            </div>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle"> Développeur Web</h3>
            <div className="cardsContain">De Octobre 2019 à Mars 2020</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://oclock.io/formations/developpeur-web"
              >
                <img className="logo_formations" src={oclocklogo} alt="Moi" />
                O'clock
              </a>
            </div>
            <div className="cardsContain">
              À O’clock, école de développement web en téléprésentiel - Labellisée Grande Ecole du Numérique.
            </div>
            <div className="cardsContain">
              700h intensives
            </div>
            <div className="cardsContain">
              <ul>
                <li>3 mois de socle : HTML5/CSS3/PHP/Javascript</li>
                <li>1 mois de spécialisation React</li>
                <li>1 mois de projet</li>
              </ul>
            </div>
            <div className="cardsContain">
              Titre Professionnel de Développeur Web et Web Mobile, niveau V
              (bac+2) (non présenté).
            </div>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle"> Agent Cynophile de Sécurité</h3>
            <div className="cardsContain">De Décembre 2013 à Mai 2014</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://metiers-chien-animalerie.fr/formation-adulte/formations-longues/agent-cynophile-de-securite"
              >
                <img className="logo_formations" src={stgervlogo} alt="Moi" />
                ACS Lycée des Combrailles
              </a>
            </div>
            <div className="cardsContain">
              Au C.F.P.P.A de Blanquefort. Formations de Saint Gervais d'Auvergne.
            </div>
            <div className="cardsContain">
              Titre Professionnel Agent Cynophile de Sécurité (niveau BEP).
            </div>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle"> Electricien</h3>
            <div className="cardsContain"> De 2005 à 2007 :</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://lycee-technique.gouv.mc/A-propos-de-l-etablissement/Sections"
              >
                <img className="logo_formations" src={lthmlogo} alt="Moi" />
                L.T.H.Monaco
              </a>
            </div>
            <div className="cardsContain">
              Au Lycée Technique et Hôtelier de Monaco.
            </div>
            <div className="cardsContain">
              BEP Métier de l’électrotechnique.
            </div>
          </div>
          <div className="cardsPage_tablet">
            <h3 className="cardsTitle">
              Agent de sécurité incendie et sauveteur secouriste
            </h3>
            <div className="cardsContain">Valide jusqu'en 2021</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://www.pompiers.fr/grand-public/devenir-sapeur-pompier/metiers-surete-et-securite/diplome-service-de-securite-incendie"
              >
                <img className="logo_formations" src={ssiaplogo} alt="Moi" />
                SSIAP
              </a>
              <a
                className="link_logo_formations"
                href="http://www.inrs.fr/services/formation/publics/sauveteur-secouriste.html"
              >
                <img className="logo_formations" src={sstlogo} alt="Moi" />
                INRS - SST
              </a>
            </div>
            <div className="cardsContain">
              Agent S.S.I.A.P 1 ( service de sécurité incendie et d’assistance à
              personnes niveau 1 ) et S.S.T ( sauveteur secouriste du travail ) à ce
              jour recyclés.
            </div>
          </div>
        </div>
      </Tablet>
      <Desktop>
        <div className="blocBehindHeader_desktop"></div>
        <h3 className="subTitle">Mes Formations</h3>
        <div className="PagesofCards">
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle">Professionnalisation Développeur Web React</h3>
            <div className="cardsContain">De Décembre 2020 à Février 2021</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://oclock.io/formations/developpeur-react"
              >
                <img className="logo_formations" src={oclocklogo} alt="Moi" />
                O'clock
              </a>
            </div>
            <div className="cardsContain">
              À O’clock, école de développement web en téléprésentiel - Labellisée Grande Ecole du Numérique.
            </div>
            <div className="cardsContain">
              Titre Professionnel de Développeur Web et Web Mobile,
              niveau V (bac+2).
            </div>
          </div>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle"> Développeur Web</h3>
            <div className="cardsContain">De Octobre 2019 à Mars 2020</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://oclock.io/formations/developpeur-web"
              >
                <img className="logo_formations" src={oclocklogo} alt="Moi" />
                O'clock
              </a>
            </div>
            <div className="cardsContain">
              À O’clock, école de développement web en téléprésentiel - Labellisée Grande Ecole du Numérique.
            </div>
            <div className="cardsContain">
              700h intensives
            </div>
            <div className="cardsContain">
              <ul>
                <li>3 mois de socle : HTML5/CSS3/PHP/Javascript</li>
                <li>1 mois de spécialisation React</li>
                <li>1 mois de projet</li>
              </ul>
            </div>
            <div className="cardsContain">
              Titre Professionnel de Développeur Web et Web Mobile, niveau V
              (bac+2) (non présenté).
            </div>
          </div>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle"> Agent Cynophile de Sécurité</h3>
            <div className="cardsContain">De Décembre 2013 à Mai 2014</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://metiers-chien-animalerie.fr/formation-adulte/formations-longues/agent-cynophile-de-securite"
              >
                <img className="logo_formations" src={stgervlogo} alt="Moi" />
                ACS Lycée des Combrailles
              </a>
            </div>
            <div className="cardsContain">
              Au C.F.P.P.A de Blanquefort. Formations de Saint Gervais d'Auvergne.
            </div>
            <div className="cardsContain">
              Titre Professionnel Agent Cynophile de Sécurité (niveau BEP).
            </div>
          </div>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle"> Electricien</h3>
            <div className="cardsContain"> De 2005 à 2007 :</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://lycee-technique.gouv.mc/A-propos-de-l-etablissement/Sections"
              >
                <img className="logo_formations" src={lthmlogo} alt="Moi" />
                L.T.H.Monaco
              </a>
            </div>
            <div className="cardsContain">
              Au Lycée Technique et Hôtelier de Monaco.
            </div>
            <div className="cardsContain">
              BEP Métier de l’électrotechnique.
            </div>
          </div>
          <div className="cardsPage_desktop">
            <h3 className="cardsTitle">
              Agent de sécurité incendie et sauveteur secouriste
            </h3>
            <div className="cardsContain">Valide jusqu'en 2021</div>
            <div className="bloc_logo_formations">
              <a
                className="link_logo_formations"
                href="https://www.pompiers.fr/grand-public/devenir-sapeur-pompier/metiers-surete-et-securite/diplome-service-de-securite-incendie"
              >
                <img className="logo_formations" src={ssiaplogo} alt="Moi" />
                SSIAP
              </a>
              <a
                className="link_logo_formations"
                href="http://www.inrs.fr/services/formation/publics/sauveteur-secouriste.html"
              >
                <img className="logo_formations" src={sstlogo} alt="Moi" />
                INRS - SST
              </a>
            </div>
            <div className="cardsContain">
              Agent S.S.I.A.P 1 ( service de sécurité incendie et d’assistance à
              personnes niveau 1 ) et S.S.T ( sauveteur secouriste du travail ) à ce
              jour recyclés.
            </div>
          </div>
        </div>
      </Desktop>
    </div>
  );
};
export default Formation;